<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { type ILeaseDocumentField, useLeaseStore } from '@register'
import { useFormProgress } from '@shared/composables/useFormProgress'

interface Props {
  fields: ILeaseDocumentField[]
  placement?: 'top' | 'right' | 'bottom' | 'left'
  hideTooltip?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  fields: () => [],
  placement: 'left',
  hideTooltip: false,
})

const filteredFilters = computed(() => {
  return props.fields.filter((field) => {
    return (
      field?.typeDefinition?.name !== 'GoogleMapsModel' &&
      // field.displayType !== 'noApproval' &&
      field?.displayType !== 'exclusiveCheckBox'
    )
  })
})

const { count, total, progress } = useFormProgress(filteredFilters)

const { showProgress } = storeToRefs(useLeaseStore())
</script>

<template>
  <LeaseCompletion
    v-if="showProgress"
    :title="`${count}/${total}`"
    :progress="progress"
    text-alignment="text-right"
    :tooltip="{ disabled: hideTooltip, placement }"
  />
</template>
